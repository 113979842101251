.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #de3141;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(190, 30, 45, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
.page_wrapper {
  overflow-x: hidden;
  max-width: 160rem;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #4D4D4D;
  font-family: "Lato", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1rem;
  color: #171717;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 0.75em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 1.625rem;
  }
}
@media (min-width: 35rem) {
  h1,
  .style_h1 {
    letter-spacing: calc((1.25rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 80);
    font-size: 1.25rem;
  }
}
@media (min-width: 35rem) and (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.25rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 35rem) and (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 1.625rem;
  }
}
h1 {
  margin-bottom: 2.25em !important;
  position: relative;
  z-index: 0;
}
h1::after {
  background: #BE1E2D;
  bottom: -0.75em;
  content: "";
  height: 0.25rem;
  left: 0;
  position: absolute;
  width: 2.5rem;
  z-index: -1;
}
.line_after {
  margin-bottom: 2.25em !important;
  position: relative;
  z-index: 0;
}
.line_after::after {
  background: #BE1E2D;
  bottom: -0.75em;
  content: "";
  height: 0.25rem;
  left: 0;
  position: absolute;
  width: 2.5rem;
  z-index: -1;
}
h2,
.style_h2 {
  letter-spacing: calc((1rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1rem;
  color: #171717;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  line-height: 1.7;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.25rem;
  }
}
@media (min-width: 35rem) {
  h2,
  .style_h2 {
    letter-spacing: calc((1rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 80);
    font-size: 1rem;
  }
}
@media (min-width: 35rem) and (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 35rem) and (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.25rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((0.9375rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
  color: #171717;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  line-height: 1.7;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(0.9375rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.125rem;
  }
}
@media (min-width: 35rem) {
  h3,
  .style_h3 {
    letter-spacing: calc((0.9375rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 80);
    font-size: 0.9375rem;
  }
}
@media (min-width: 35rem) and (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(0.9375rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 35rem) and (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.125rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 80);
  font-size: 0.875rem;
  color: #171717;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  line-height: 1.7;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  color: #4D4D4D;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.75rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.75rem;
  color: #4D4D4D;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 0.875rem;
  }
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #4D4D4D;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #BE1E2D;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(190, 30, 45, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"] {
  display: block;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #4D4D4D;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #4D4D4D;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #BE1E2D;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(190, 30, 45, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  color: #BE1E2D;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #d9d9d9;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #d9d9d9;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #F2F2F2;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #BE1E2D;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #d9d9d9;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #d9d9d9;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid #d9d9d9;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1rem;
  }
}
label {
  cursor: pointer;
}
back input[type="number"] {
  -moz-appearance: textfield;
}
back input[type="number"]::-webkit-inner-spin-button,
back input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #4D4D4D;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #8d8d8d;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #8d8d8d;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #8d8d8d;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #8d8d8d;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #BE1E2D;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #BE1E2D;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.1875rem;
  color: #4D4D4D;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: none;
  border-bottom: 0.0625rem solid #BEBEBE;
  border-left: 0.0625rem solid #BEBEBE;
  border-radius: 0rem;
  box-shadow: 0 0 0 #BEBEBE inset;
  color: #4D4D4D;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #BE1E2D;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #BE1E2D;
  box-shadow: 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
  color: #4D4D4D;
}
.input:focus,
.textarea:focus {
  border-color: #BE1E2D;
  box-shadow: 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
  color: #4D4D4D;
}
.textarea {
  border: 0.0625rem solid #BEBEBE;
}
.input {
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 2.75rem;
  }
}
.textarea {
  line-height: 1.5;
  overflow-x: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
  position: relative;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #BE1E2D;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #BE1E2D;
  box-shadow: 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
  color: #4D4D4D;
}
.control input:focus ~ .indicator {
  border-color: #BE1E2D;
  box-shadow: 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
  color: #4D4D4D;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #BEBEBE;
  border-radius: 0rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #4D4D4D;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.control label {
  z-index: 2;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.0625rem solid #BEBEBE;
  border-radius: 0rem;
  color: #4D4D4D;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #4D4D4D;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #BE1E2D;
}
.select.error.focus {
  border-color: #BE1E2D;
  box-shadow: 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
  color: #4D4D4D;
}
.select.focus {
  border-color: #BE1E2D;
  box-shadow: 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
  color: #4D4D4D;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #4D4D4D;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.625rem;
  }
}
select option {
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.625rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4D4D4D;
}
select:focus::-ms-value {
  background: none;
  color: #4D4D4D;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(190, 30, 45, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(190, 30, 45, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(190, 30, 45, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(190, 30, 45, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes move_from_left {
  0% {
    -webkit-transform: translateX(-7em);
            transform: translateX(-7em);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes move_from_left {
  0% {
    -webkit-transform: translateX(-7em);
            transform: translateX(-7em);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes move_from_right {
  0% {
    -webkit-transform: translateX(4em);
            transform: translateX(4em);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes move_from_right {
  0% {
    -webkit-transform: translateX(4em);
            transform: translateX(4em);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes heartbeat {
  from {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: scale(1) rotate(-90deg);
            transform: scale(1) rotate(-90deg);
  }
  10% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(0.91) rotate(-90deg);
            transform: scale(0.91) rotate(-90deg);
  }
  17% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(0.98) rotate(-90deg);
            transform: scale(0.98) rotate(-90deg);
  }
  33% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(0.87) rotate(-90deg);
            transform: scale(0.87) rotate(-90deg);
  }
  45% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1) rotate(-90deg);
            transform: scale(1) rotate(-90deg);
  }
}
@keyframes heartbeat {
  from {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: scale(1) rotate(-90deg);
            transform: scale(1) rotate(-90deg);
  }
  10% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(0.91) rotate(-90deg);
            transform: scale(0.91) rotate(-90deg);
  }
  17% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(0.98) rotate(-90deg);
            transform: scale(0.98) rotate(-90deg);
  }
  33% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(0.87) rotate(-90deg);
            transform: scale(0.87) rotate(-90deg);
  }
  45% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1) rotate(-90deg);
            transform: scale(1) rotate(-90deg);
  }
}
@-webkit-keyframes slide_in_blurred_left {
  0% {
    -webkit-transform: translateX(-62.5rem) scaleX(2.5) scaleY(0.2);
            transform: translateX(-62.5rem) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(2.5rem);
            filter: blur(2.5rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide_in_blurred_left {
  0% {
    -webkit-transform: translateX(-62.5rem) scaleX(2.5) scaleY(0.2);
            transform: translateX(-62.5rem) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(2.5rem);
            filter: blur(2.5rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #d9d9d9;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #BE1E2D;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #BE1E2D;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #BE1E2D;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #BE1E2D;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 1.5em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    font-weight: 400;
    min-width: 1.5625rem;
    padding-right: 0.375em;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  .side_nav_wrapper {
    padding: 3em 0;
    height: 100vh;
  }
  .side_nav_wrapper .icons_container {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    padding-right: 1.5em;
    position: relative;
    z-index: 400;
  }
  .side_nav_wrapper .icons_container .icon_link {
    height: 4.375rem;
    margin-right: 1.5em;
    width: 4.375rem;
  }
  .side_nav_wrapper .icons_container .icon_link:last-child {
    margin: 0;
  }
  .side_nav_wrapper .icons_container .icon_link > span {
    height: 3.875rem;
    width: 3.875rem;
  }
  .side_nav_wrapper .icons_container .icon_link .icon {
    height: 1.875rem;
    width: 1.875rem;
  }
  .side_nav_wrapper .related_links {
    bottom: 1.5em;
    position: absolute;
    right: 1.5em;
    z-index: 400;
  }
  .side_nav_wrapper .related_links ul {
    display: -webkit-box;
    display: flex;
  }
  .side_nav_wrapper .related_links ul li {
    position: relative;
    z-index: 0;
  }
  .side_nav_wrapper .related_links ul li::after {
    content: "|";
    padding: 0 0.375em;
    z-index: -1;
  }
  .side_nav_wrapper .related_links ul li:last-child::after {
    display: none;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_ver {
    bottom: 100%;
    display: inline-block;
    fill: #a0a0a0;
    height: 4.6875rem;
    line-height: 4.6875rem;
    opacity: 0.6;
    position: absolute;
    right: 0.125rem;
    width: 0.5625rem;
  }
  .author_ver_sidebar {
    display: none !important;
  }
  .breadcrumbs {
    display: none;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .button {
    border-radius: 0rem;
    padding: 0 1.5em;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    padding: 0.375em 0;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
    outline: 0;
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #BE1E2D inset;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    padding: 0.375em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .button.bottom_spacing {
    margin-bottom: 0.75em;
  }
  .button_overview {
    display: -webkit-box;
    display: flex;
  }
  .button_overview .button {
    margin-right: 0.75em;
  }
  .icon_link {
    -webkit-box-align: center;
            align-items: center;
    background: #e5e5e5;
    border-radius: 50%;
    color: #BE1E2D;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.375em;
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transition: -webkit-transform 600ms ease-out;
    transition: -webkit-transform 600ms ease-out;
    transition: transform 600ms ease-out;
    transition: transform 600ms ease-out, -webkit-transform 600ms ease-out;
    width: 2.75rem;
  }
  .icon_link > span {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.125rem solid #BEBEBE;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    height: 2.375rem;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-transition: background 450ms ease-out;
    transition: background 450ms ease-out;
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
    width: 2.375rem;
  }
  .icon_link .icon {
    fill: #4D4D4D;
    height: 1.25rem;
    -webkit-transition: fill 300ms ease-out;
    transition: fill 300ms ease-out;
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms;
    width: 1.25rem;
  }
  .icon_link:hover,
  .icon_link:focus {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .icon_link:hover > span,
  .icon_link:focus > span {
    border: 0.125rem solid #fff;
    background: #BE1E2D;
  }
  .icon_link:hover .icon,
  .icon_link:focus .icon {
    fill: #fff;
  }
  .icon_link_large {
    height: 4.375rem;
    margin-right: 1.5em;
    width: 4.375rem;
  }
  .icon_link_large:last-child {
    margin: 0;
  }
  .icon_link_large > span {
    height: 3.875rem;
    width: 3.875rem;
  }
  .icon_link_large .icon {
    height: 1.875rem;
    width: 1.875rem;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #BE1E2D;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #BE1E2D;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .steuernews_call_to_action {
    border-radius: 0rem;
    border: 0.0625rem solid #F2F2F2;
    display: inline-block;
    margin: 1.5em 0 1.5em;
    padding: 0.75em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .company_logo {
    display: block;
    width: 18.75rem;
  }
  .content_bg_image_container {
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    z-index: 0;
  }
  .content_bg_image_container > .container {
    width: 100%;
  }
  .content_bg_image_container.center {
    background-position-x: center;
  }
  .content_bg_image_container.left {
    background-position-x: left;
  }
  .content_bg_image_container.right {
    background-position-x: right;
  }
  .content_bg_image_container.middle {
    background-position-y: center;
  }
  .content_bg_image_container.top {
    background-position-y: top;
  }
  .content_bg_image_container.bottom {
    background-position-y: bottom;
  }
  .content_bg_image_container.show_overlay::after {
    background: rgba(190, 30, 45, 0.8);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .content_bg_image_container.fixed {
    background-attachment: fixed;
  }
  html.ios .content_bg_image_container.fixed,
  html.mobile .content_bg_image_container.fixed {
    background-attachment: scroll;
  }
  .content_bg_image_container.full_width {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #F2F2F2;
    border-top: 0.125rem solid #d9d9d9;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.75rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.3em;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.75em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: block;
    font-family: "Raleway", sans-serif;
    margin-left: 30%;
    width: 100%;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul li:hover > a::after,
  .dropdown_menu > ul li.hover > a::after {
    right: 0;
  }
  .dropdown_menu > ul li:hover > ul,
  .dropdown_menu > ul li.hover > ul {
    visibility: visible;
  }
  .dropdown_menu > ul li .icon {
    box-sizing: content-box;
    display: inline-block;
    height: 0.625rem;
    padding-left: 0.75em;
    width: 0.625rem;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li:last-child {
    border-radius: 0rem;
    padding: 0 1.5em;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 0;
  }
  .dropdown_menu > ul > li:last-child > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .dropdown_menu > ul > li:last-child .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .dropdown_menu > ul > li:last-child .text {
    padding: 0.375em 0;
  }
  .dropdown_menu > ul > li:last-child .icon + .text {
    padding-left: 0.5em;
  }
  .dropdown_menu > ul > li:last-child:focus {
    box-shadow: 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
    outline: 0;
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .dropdown_menu > ul > li:last-child:focus .icon {
    fill: #fff;
  }
  .dropdown_menu > ul > li:last-child:hover {
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .dropdown_menu > ul > li:last-child:hover .icon {
    fill: #fff;
  }
  .dropdown_menu > ul > li:last-child:active,
  .dropdown_menu > ul > li:last-child.active {
    box-shadow: 0 0 0.1875rem #BE1E2D inset;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .dropdown_menu > ul > li:last-child:active > span,
  .dropdown_menu > ul > li:last-child.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .dropdown_menu > ul > li:last-child:active .icon,
  .dropdown_menu > ul > li:last-child.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .dropdown_menu > ul > li:last-child:active .text,
  .dropdown_menu > ul > li:last-child.active .text {
    padding: 0.375em 0;
  }
  .dropdown_menu > ul > li:last-child:active .icon + .text,
  .dropdown_menu > ul > li:last-child.active .icon + .text {
    padding-left: 0.5em;
  }
  .dropdown_menu > ul > li:last-child.bottom_spacing {
    margin-bottom: 0.75em;
  }
  .dropdown_menu > ul > li > a {
    word-wrap: break-word;
    letter-spacing: calc((1rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1rem;
    font-family: "Raleway", sans-serif;
    line-height: 1.7;
    margin: 0 0 0.75em;
    padding-top: 0.75em;
    -webkit-box-align: center;
            align-items: center;
    color: #171717;
    display: -webkit-box;
    display: flex;
    font-weight: 700;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin: 0;
    padding: 0.75em 1.5em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 0;
    white-space: normal;
  }
  .dropdown_menu > ul > li > a::after {
    background: #F2F2F2;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 102%;
    top: 0;
    -webkit-transition: right 150ms ease-out;
    transition: right 150ms ease-out;
    z-index: -1;
  }
  .dropdown_menu > ul > li > a.active {
    color: #fff;
  }
  .dropdown_menu > ul > li > a.active::after {
    background: #BE1E2D;
    right: 0;
  }
  .dropdown_menu > ul > li ul {
    background: #F2F2F2;
    padding: 0.75em 0;
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #4D4D4D;
    display: block;
    font-size: 1.125rem;
    font-weight: 700;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0.375em 1.5em;
    text-align: right;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a:hover {
    color: #BE1E2D;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #BE1E2D;
  }
  .dropdown_menu > ul > li ul ul {
    left: 100%;
    position: absolute;
    top: -0.75em;
  }
  footer {
    position: relative;
    overflow: hidden;
  }
  footer .related_links {
    margin-bottom: 1.5em;
    margin-top: 3em;
  }
  .footer_fixed_wrapper {
    margin-top: 3.75rem;
    padding-top: 1.875rem;
    border-top: 0.25rem solid #BE1E2D;
  }
  .footer_fixed_wrapper .links_overview {
    margin: 3em 0;
  }
  .footer_fixed_wrapper .company_name {
    display: inline-block;
  }
  .footer_fixed_wrapper .symbol_footer {
    display: none;
  }
  .form_disclaimer {
    background: #F2F2F2;
    border-radius: 0rem;
    color: #4D4D4D;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #BE1E2D;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #BEBEBE;
    border-radius: 0rem 0 0 0rem;
    color: #4D4D4D;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.625rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0rem 0rem 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    padding: 0.375em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #BE1E2D inset;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    padding: 0.375em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .page_wrapper {
    position: relative;
  }
  .page_wrapper .side_nav_wrapper {
    display: none;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .container_desktop {
    padding: 0 0 0 18%;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .header_wrapper .mobile_navigation_wrapper {
    background: #F2F2F2;
    position: fixed;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
  }
  .header_wrapper .mobile_navigation_wrapper > .container {
    height: 3.5rem;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .header_wrapper .mobile_navigation_wrapper .icons_container {
    display: -webkit-box;
    display: flex;
  }
  .header_wrapper .logo_wrapper {
    margin-top: 3.5rem;
    padding: 0.75em 0;
  }
  .header_wrapper .logo_wrapper .company_logo {
    margin-left: auto;
    margin-right: auto;
  }
  .slogan_wrapper {
    background: #BE1E2D;
    margin-top: -3em;
    padding: 0.75em 1.5em;
    text-align: center;
    position: relative;
    z-index: 10;
  }
  .slogan_wrapper > p {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    word-wrap: break-word;
    letter-spacing: calc((1rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1rem;
    color: #171717;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 0.75em;
    text-transform: uppercase;
    letter-spacing: calc((1.375rem + 0.875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.375rem;
    color: #fff;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-transform: none;
  }
  .header_image img {
    display: block;
    margin: 0 auto;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
  }
  .infolist_top_link a:hover .icon,
  .infolist_top_link a:focus .icon {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #BE1E2D;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #F2F2F2;
    border-radius: 0rem;
    color: #4D4D4D;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: baseline;
            align-items: baseline;
    background: #F2F2F2;
    border-radius: 0rem;
    color: #4D4D4D;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 3em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #d9d9d9;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .job_benefit_overview .entry {
    margin-bottom: 3em;
  }
  .job_benefit_overview .entry:last-child {
    margin-bottom: 0;
  }
  .job_overview {
    margin-top: 1.5em;
    width: 100%;
  }
  .job_overview .job_list_entries a {
    background: #F2F2F2;
    display: block;
    margin-bottom: 0.75em;
  }
  .job_overview .job_list_entries a .title {
    padding: 0.375em 1.5em;
    margin: 0;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.75em;
  }
  .main_container {
    position: relative;
  }
  main {
    margin-top: 3.75rem;
  }
  .startpage .element_padding {
    margin-bottom: 3.75rem;
  }
  .element_padding_top {
    margin-top: 3.75rem;
  }
  .element_padding_half {
    margin-bottom: 1.875rem;
  }
  .element_padding_top_half {
    margin-top: 1.875rem;
  }
  .overview .touch_link {
    margin-bottom: 1.5em;
    padding-left: 1.5em;
    position: relative;
    z-index: 0;
  }
  .overview .touch_link::before {
    background: #F2F2F2;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    width: 0.25rem;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: -1;
  }
  .overview .touch_link:hover::before,
  .overview .touch_link:focus::before {
    bottom: 0.375em;
    top: 0.375em;
    background: #BE1E2D;
  }
  .seperator_bottom > .entry {
    border-bottom: 1.5em solid #F2F2F2;
    margin-bottom: 1.5em;
    padding-bottom: 1.5em;
  }
  .seperator_bottom > .entry:last-child {
    border-bottom: 0;
    padding-bottom: 3em;
    margin-bottom: 0;
  }
  .seperator_bottom > .entry .button {
    margin: 1.5em 0;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .text {
    display: none;
    color: #4D4D4D;
    font-size: 1.25rem;
    letter-spacing: 0.0625rem;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-left: 0.75em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2.5rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #171717;
    border-radius: 1.25rem;
    display: block;
    height: 0.1875rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.25rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    background: #BE1E2D;
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
    width: 80%;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.3125rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(77, 77, 77, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.5em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    word-wrap: break-word;
    letter-spacing: calc((0.9375rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.9375rem;
    color: #171717;
    font-family: "Raleway", sans-serif;
    line-height: 1.7;
    margin: 0 0 0.75em;
    padding-top: 0.75em;
    color: #4D4D4D;
    display: block;
    font-size: 112.5%;
    font-weight: 700;
    line-height: 1.875rem;
    padding: 0 1.5em;
    text-transform: uppercase;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    background: #BE1E2D;
    color: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
    margin-bottom: 1.5em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #4D4D4D;
    display: block;
    padding: 0.25em 1.5em 0.25em 2.25em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #BE1E2D;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.0625rem solid #d9d9d9;
    border-top: 0.0625rem solid #d9d9d9;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block !important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .onlinetools_popup_overview .overview_title {
    margin-bottom: 2.25em !important;
    position: relative;
    z-index: 0;
    margin-top: 1.875rem;
  }
  .onlinetools_popup_overview .overview_title::after {
    background: #BE1E2D;
    bottom: -0.75em;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 2.5rem;
    z-index: -1;
  }
  .onlinetools_popup_overview .entry {
    margin-bottom: 1.5em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .onlinetools_popup_overview .touch_link .icon_wrapper {
    -webkit-box-align: center;
            align-items: center;
    background: #e5e5e5;
    border-radius: 50%;
    color: #BE1E2D;
    display: -webkit-box;
    display: flex;
    height: 6.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.375em;
    margin-bottom: 0.75em;
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transition: -webkit-transform 600ms ease-out;
    transition: -webkit-transform 600ms ease-out;
    transition: transform 600ms ease-out;
    transition: transform 600ms ease-out, -webkit-transform 600ms ease-out;
    width: 6.25rem;
  }
  .onlinetools_popup_overview .touch_link .icon_wrapper > span {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.125rem solid #BEBEBE;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    height: 5.625rem;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-transition: background 450ms ease-out;
    transition: background 450ms ease-out;
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
    width: 5.625rem;
  }
  .onlinetools_popup_overview .touch_link .icon_wrapper .icon {
    fill: #4D4D4D;
    height: 3.125rem;
    -webkit-transition: fill 300ms ease-out;
    transition: fill 300ms ease-out;
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms;
    width: 3.125rem;
  }
  .onlinetools_popup_overview .touch_link:hover .icon_wrapper,
  .onlinetools_popup_overview .touch_link:focus .icon_wrapper {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .onlinetools_popup_overview .touch_link:hover .icon_wrapper > span,
  .onlinetools_popup_overview .touch_link:focus .icon_wrapper > span {
    border: 0.125rem solid #fff;
    background: #BE1E2D;
  }
  .onlinetools_popup_overview .touch_link:hover .icon_wrapper .icon,
  .onlinetools_popup_overview .touch_link:focus .icon_wrapper .icon {
    fill: #fff;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(77, 77, 77, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #BE1E2D;
    border-radius: 0rem 0rem 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map {
    margin-top: 3em;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #F2F2F2;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(242, 242, 242, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    border-radius: 0rem;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #de3141;
    border: 0.0625rem solid #de3141;
    box-shadow: 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #de3141;
    border: 0.0625rem solid #de3141;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 2;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 2;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.875rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #F2F2F2;
    border-radius: 0rem;
    color: #4D4D4D;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #d9d9d9;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0rem 0rem 0;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  form[role="search"] button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    padding: 0.375em 0;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.5em;
  }
  form[role="search"] button[disabled] {
    background-color: #F2F2F2;
    border-color: #e5e5e5;
    color: #cdcdcd;
  }
  form[role="search"] button[disabled] .icon {
    fill: #4D4D4D;
  }
  form[role="search"] button > span {
    width: 100%;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
    position: relative;
    z-index: 10;
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: 2.625rem;
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #BEBEBE;
    border-radius: 0rem 0 0 0rem;
    box-shadow: 0 0 0 #BEBEBE inset;
    color: #4D4D4D;
    flex-basis: 100%;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    height: 2.75rem;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #BE1E2D;
    box-shadow: 0 0 0.1875rem #BE1E2D inset, 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
    color: #4D4D4D;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.75em;
    padding-top: 0.375em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    height: 2.75rem;
    position: relative;
    width: 2.75rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .toggle_search_box label:hover ~ a > span {
    border: 0.125rem solid #fff;
    background: #BE1E2D;
  }
  .toggle_search_box label:hover ~ a .icon {
    fill: #fff;
  }
  .toggle_search_box a {
    fill: #4D4D4D;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .toggle_search_box .open_search_box {
    display: -webkit-box;
    display: flex;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(77, 77, 77, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    border-radius: 50%;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #de3141;
    border-color: #de3141;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #fff;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    width: 2.75rem;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 115%;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  .social_media_share {
    margin-bottom: 1.875rem;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5em 0 0.75em 0;
    padding: 0;
  }
  .social_media_share li {
    line-height: 0;
    list-style: none;
    margin: 0.375em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #4D4D4D;
    cursor: pointer;
    display: block;
    font-family: "Raleway", sans-serif;
    padding-right: 1.0625rem;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0rem;
    height: 2.125rem;
    width: 2.125rem;
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transition: -webkit-transform 600ms ease-out;
    transition: -webkit-transform 600ms ease-out;
    transition: transform 600ms ease-out;
    transition: transform 600ms ease-out, -webkit-transform 600ms ease-out;
  }
  .social_media_share a:hover .icon,
  .social_media_share a:focus .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .social_media_share a .text {
    font-weight: 400;
    padding-left: 1.0625rem;
    padding-right: 0.75em;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: #BE1E2D;
    border-radius: 50%;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_share a.facebook .icon,
  .social_media_share a.twitter .icon {
    border-radius: 0;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .overview_title {
    margin-bottom: 2.25em !important;
    position: relative;
    z-index: 0;
    margin-top: 1.875rem;
  }
  .steuernews_preview .overview_title::after {
    background: #BE1E2D;
    bottom: -0.75em;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 2.5rem;
    z-index: -1;
  }
  .steuernews_preview .entry {
    margin-bottom: 2.25em;
  }
  .steuernews_preview .touch_link:hover .image,
  .steuernews_preview .touch_link:focus .image {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  .steuernews_preview .image {
    display: inline-block;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    line-height: 0;
    margin-bottom: 0.75em;
    -webkit-box-ordinal-group: 2;
            order: 1;
    position: relative;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 300ms ease-out;
    transition: -webkit-transform 300ms ease-out;
    transition: transform 300ms ease-out;
    transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  }
  .steuernews_preview .image .icon {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    background: #BE1E2D;
    bottom: 0;
    height: 2.75rem;
    position: absolute;
    right: 0;
    width: 2.75rem;
    z-index: 1;
  }
  .steuernews_preview .image .icon svg {
    fill: #fff;
    height: 1.875rem;
    width: 1.875rem;
  }
  .steuernews_preview .photo {
    position: relative;
  }
  .steuernews_preview .photo .bg_image {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 10rem;
    margin-right: 1.5em;
    width: 100%;
  }
  .steuernews_preview .icon {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    background: #BE1E2D;
    bottom: 0;
    height: 2.75rem;
    position: absolute;
    right: 0;
    width: 2.75rem;
    z-index: 1;
  }
  .steuernews_preview .icon svg {
    fill: #fff;
    height: 1.875rem;
    width: 1.875rem;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    font-weight: 400;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    display: none;
    float: right;
    margin-left: 1.5em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    padding-left: 0;
  }
  .steuernews_issues a {
    background: #F2F2F2;
    border-radius: 0rem;
    display: inline-block;
    margin: 0 0.75em 0.75em 0;
    padding: 0 1.5em;
    white-space: nowrap;
    line-height: 2.75rem;
  }
  .steuernews_disclaimer {
    clear: both;
  }
  .steuernews_sidebar {
    margin: 1.5em 0 0.75em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.625rem;
  }
  .steuernews_current_issue .overview_title {
    margin-bottom: 2.25em !important;
    position: relative;
    z-index: 0;
  }
  .steuernews_current_issue .overview_title::after {
    background: #BE1E2D;
    bottom: -0.75em;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 2.5rem;
    z-index: -1;
  }
  .steuernews_current_issue a {
    text-decoration: none;
    display: block;
    padding: 0.1875em 0;
  }
  .steuernews_current_issue a:hover,
  .steuernews_current_issue a:focus {
    text-decoration: underline;
  }
  .steuernews_current_issue a.active {
    font-weight: 700;
    pointer-events: none;
  }
  .steuernews_contact_person {
    background: #F2F2F2;
    border-radius: 0rem;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.5em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0rem;
    color: #BE1E2D;
    display: block;
    line-height: 1.5;
    margin: 1em 0;
    padding: 0.75em;
    text-align: center;
  }
  .highlight_keyword {
    background: #BE1E2D;
    color: #fff;
    outline: 0.125rem solid #BE1E2D;
  }
  .steuernews_issues_select_wrapper .overview_title {
    margin-bottom: 2.25em !important;
    position: relative;
    z-index: 0;
  }
  .steuernews_issues_select_wrapper .overview_title::after {
    background: #BE1E2D;
    bottom: -0.75em;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 2.5rem;
    z-index: -1;
  }
  .steuernews_issues_select {
    margin-bottom: 0.625rem;
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    max-width: 30rem;
    width: 100%;
  }
  .steuernews_issues_select .select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 0;
  }
  .steuernews_issues_select .button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    flex-shrink: 0;
  }
  .steuernews_navigation {
    margin: 1.5em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.5;
    padding: 0 1.5em;
  }
  .steuernews_newsletter_subscribe {
    background: #F2F2F2;
    border-radius: 0rem;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_search {
    margin: 0 0 1.5em;
  }
  .steuernews_search .overview_title {
    margin-bottom: 2.25em !important;
    position: relative;
    z-index: 0;
  }
  .steuernews_search .overview_title::after {
    background: #BE1E2D;
    bottom: -0.75em;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 2.5rem;
    z-index: -1;
  }
  .steuernews_search .search {
    margin: 0 0 0.75em;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.75em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    display: -webkit-box;
    display: flex;
    margin: 0 1.5em 0.75em 0;
    max-width: 25rem;
    position: relative;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 300ms ease-out;
    transition: -webkit-transform 300ms ease-out;
    transition: transform 300ms ease-out;
    transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .photo .icon {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    background: #BE1E2D;
    bottom: 0;
    height: 2.75rem;
    position: absolute;
    right: 0;
    width: 2.75rem;
    z-index: 1;
  }
  .team_overview .photo .icon svg {
    fill: #fff;
    height: 1.875rem;
    width: 1.875rem;
  }
  .team_overview .photo:hover,
  .team_overview .photo:focus {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.75em;
  }
  .team_overview .info .caller {
    text-decoration: none;
  }
  .team_overview .info:hover .title,
  .team_overview .info:focus .title {
    color: #BE1E2D;
  }
  .team_overview .buttons {
    display: -webkit-box;
    display: flex;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 25rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .team_overview_popup .caller:hover,
  .team_overview_popup .caller:focus {
    cursor: pointer;
  }
  .team_overview_popup .popup_info {
    background-color: rgba(77, 77, 77, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: 99999;
  }
  .team_overview_popup .popup_info.show {
    opacity: 1;
    visibility: visible;
  }
  .team_overview_popup .popup_info .close {
    background-color: #BE1E2D;
    border-radius: 50%;
    cursor: pointer;
    fill: #fff;
    position: absolute;
    right: -1.375rem;
    top: -1.375rem;
    -webkit-transition: background-color 300ms;
    transition: background-color 300ms;
    height: 2.75rem;
    width: 2.75rem;
  }
  .team_overview_popup .popup_info .close:focus,
  .team_overview_popup .popup_info .close:hover {
    background-color: #de3141;
  }
  .team_overview_popup .popup_info .info {
    -webkit-box-align: stretch;
            align-items: stretch;
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    height: 90%;
    left: 50%;
    padding: 1.5em 0 1.5em 1.5em;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 17.5rem;
  }
  .team_overview_popup .popup_info .info .text {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 1.5em;
  }
  .teaser_overview .teaser {
    margin-bottom: 3em;
  }
  .teaser_overview .teaser .icon_wrapper {
    -webkit-box-align: center;
            align-items: center;
    background: #e5e5e5;
    border-radius: 50%;
    color: #BE1E2D;
    display: -webkit-box;
    display: flex;
    height: 6.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.375em;
    margin-bottom: 0.75em;
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transition: -webkit-transform 600ms ease-out;
    transition: -webkit-transform 600ms ease-out;
    transition: transform 600ms ease-out;
    transition: transform 600ms ease-out, -webkit-transform 600ms ease-out;
    width: 6.25rem;
  }
  .teaser_overview .teaser .icon_wrapper > span {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.125rem solid #BEBEBE;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    height: 5.625rem;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-transition: background 450ms ease-out;
    transition: background 450ms ease-out;
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
    width: 5.625rem;
  }
  .teaser_overview .teaser .icon_wrapper .icon {
    fill: #4D4D4D;
    height: 3.125rem;
    -webkit-transition: fill 300ms ease-out;
    transition: fill 300ms ease-out;
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms;
    width: 3.125rem;
  }
  .teaser_overview .teaser .title {
    text-transform: uppercase;
  }
  .teaser_overview .teaser.touch_link:hover .icon_wrapper,
  .teaser_overview .teaser.touch_link:focus .icon_wrapper {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .teaser_overview .teaser.touch_link:hover .icon_wrapper > span,
  .teaser_overview .teaser.touch_link:focus .icon_wrapper > span {
    border: 0.125rem solid #fff;
    background: #BE1E2D;
  }
  .teaser_overview .teaser.touch_link:hover .icon_wrapper .icon,
  .teaser_overview .teaser.touch_link:focus .icon_wrapper .icon {
    fill: #fff;
  }
  .content_bg_image_container .teaser_overview .teaser {
    color: #fff;
    margin-bottom: 0;
    padding: 3em 0;
  }
  .content_bg_image_container .teaser_overview .teaser .content .title {
    padding-top: 0;
  }
  .content_bg_image_container .teaser_overview .teaser .button {
    background: #fff;
    border: 0.0625rem solid #fff;
    color: #BE1E2D;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .content_bg_image_container .teaser_overview .teaser .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .content_bg_image_container .teaser_overview .teaser .button .icon {
    fill: #BE1E2D;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .content_bg_image_container .teaser_overview .teaser .button .text {
    padding: 0.375em 0;
  }
  .content_bg_image_container .teaser_overview .teaser .button .icon + .text {
    padding-left: 0.5em;
  }
  .content_bg_image_container .teaser_overview .teaser .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(190, 30, 45, 0.2);
    outline: 0;
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .content_bg_image_container .teaser_overview .teaser .button:focus .icon {
    fill: #fff;
  }
  .content_bg_image_container .teaser_overview .teaser .button:hover {
    background: #de3141;
    border-color: #de3141;
    color: #fff;
  }
  .content_bg_image_container .teaser_overview .teaser .button:hover .icon {
    fill: #fff;
  }
  .content_bg_image_container .teaser_overview .teaser .button:active,
  .content_bg_image_container .teaser_overview .teaser .button.active {
    box-shadow: 0 0 0.1875rem #BE1E2D inset;
    background: #BE1E2D;
    border: 0.0625rem solid #BE1E2D;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .content_bg_image_container .teaser_overview .teaser .button:active > span,
  .content_bg_image_container .teaser_overview .teaser .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .content_bg_image_container .teaser_overview .teaser .button:active .icon,
  .content_bg_image_container .teaser_overview .teaser .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .content_bg_image_container .teaser_overview .teaser .button:active .text,
  .content_bg_image_container .teaser_overview .teaser .button.active .text {
    padding: 0.375em 0;
  }
  .content_bg_image_container .teaser_overview .teaser .button:active .icon + .text,
  .content_bg_image_container .teaser_overview .teaser .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .content_bg_image_container .teaser_overview .teaser .title {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    word-wrap: break-word;
    letter-spacing: calc((1rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1rem;
    color: #171717;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 0.75em;
    text-transform: uppercase;
    color: #fff;
  }
  .testimonial_overview .testimonial {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_keywords_register_index .title_overview {
    margin-bottom: 2.25em !important;
    position: relative;
    z-index: 0;
  }
  .steuernews_keywords_register_index .title_overview::after {
    background: #BE1E2D;
    bottom: -0.75em;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 2.5rem;
    z-index: -1;
  }
  .keyword_register {
    margin-bottom: 0.75em;
  }
  .keyword_register:after {
    clear: both;
  }
  .keyword_register:after,
  .keyword_register:before {
    content: "";
    display: table;
  }
  .keyword_register a {
    background: #F2F2F2;
    border-radius: 50%;
    float: left;
    margin: 0 0.75em 0.75em 0;
    text-align: center;
    text-decoration: none;
    line-height: 2.75rem;
    width: 2.75rem;
  }
  .keyword_register a:hover,
  .keyword_register a:focus {
    background: #BE1E2D;
    color: #fff;
    text-decoration: none;
  }
  .steuernews_keywords {
    margin-bottom: 0.625rem;
  }
  .steuernews_keywords .overview_title {
    margin-bottom: 2.25em !important;
    position: relative;
    z-index: 0;
  }
  .steuernews_keywords .overview_title::after {
    background: #BE1E2D;
    bottom: -0.75em;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 2.5rem;
    z-index: -1;
  }
  .steuernews_keywords ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none !important;
    padding-left: 0;
  }
  .steuernews_keywords ul li {
    padding-left: 0;
  }
  .steuernews_keywords a {
    background: #F2F2F2;
    border-radius: 0rem;
    display: inline-block;
    margin: 0 0.75em 0.75em 0;
    padding: 0 1.5em;
    text-decoration: none;
    white-space: nowrap;
    line-height: 2.75rem;
  }
  .steuernews_keywords a:hover,
  .steuernews_keywords a:focus {
    background: #BE1E2D;
    color: #fff;
    text-decoration: none;
  }
  .steuernews_keywords_register .keywords_register_entries a {
    display: inline-block;
    max-width: 12.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .steuernews_content [href*="thema"] {
    color: #4D4D4D;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
    z-index: 1;
  }
  .checkbox .tooltip_wrapper {
    position: static;
  }
  [role="tooltip"] {
    background: #F2F2F2;
    border: 0.0625rem solid #d9d9d9;
    border-radius: 0rem;
    bottom: 100%;
    color: #4D4D4D;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #F2F2F2;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #d9d9d9;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .youtube_player {
    margin-top: 3em;
  }
  .youtube_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .youtube_wrapper.show_message {
    min-height: 18.75rem;
  }
  .youtube_wrapper.show_message .overlay {
    opacity: 1;
    visibility: visible;
  }
  .youtube_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .youtube_wrapper .overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: #F2F2F2;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    min-height: 100%;
    opacity: 0;
    padding: 1.125em;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    width: 100%;
    z-index: 1;
  }
  .youtube_wrapper .overlay .text_wrapper a {
    display: block;
  }
  .youtube_wrapper .text_wrapper {
    font-size: 85%;
    margin: 0 auto;
    max-width: 37.5rem;
    width: 100%;
  }
  .youtube_wrapper .button {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin-top: 1.5em;
  }
}
@media screen and (min-width: 93.75rem) {
  .contact_wrapper {
    display: block;
  }
  .contact_wrapper p {
    margin-bottom: 0.75em;
  }
}
@media screen and (min-width: 75rem) {
  .author_ver {
    display: none !important;
  }
}
@media screen and (min-width: 75rem) {
  .author_ver_sidebar {
    bottom: 10%;
    display: inline-block !important;
    fill: #a0a0a0;
    height: 4.6875rem;
    line-height: 4.6875rem;
    opacity: 0.6;
    position: absolute;
    right: 0.25rem;
    width: 0.5625rem;
    z-index: 400;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo {
    width: 27.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo {
    width: 35.625rem;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo {
    width: 34.375rem;
  }
}
@media screen and (min-width: 160rem) {
  .content_bg_image_container.full_width {
    margin-left: calc(50% -  160rem / 2);
    margin-right: calc(50% -  160rem / 2);
  }
}
@media screen and (min-width: 75rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 150;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li:last-child > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li:last-child > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li:last-child:active > span,
  .dropdown_menu > ul > li:last-child.active > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li:last-child:active > span,
  .dropdown_menu > ul > li:last-child.active > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    font-size: calc(1rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 35rem) {
  .dropdown_menu > ul > li > a {
    letter-spacing: calc((1rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 80);
    font-size: 1rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    font-size: calc(1rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 75rem) {
  footer .related_links {
    display: none;
  }
  footer > .container {
    padding: 0 0 0 18%;
  }
}
@media screen and (min-width: 20rem) {
  .footer_fixed_wrapper {
    margin-top: calc(3.75rem + 2.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .footer_fixed_wrapper {
    margin-top: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .footer_fixed_wrapper {
    padding-top: calc(1.875rem + 1.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .footer_fixed_wrapper {
    padding-top: 3.125rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .footer_fixed_wrapper .symbol_footer {
    position: absolute;
    display: block;
    left: 0;
    opacity: 0.15;
    bottom: 0;
    -webkit-transform: translateX(-30%) translateY(40%);
            transform: translateX(-30%) translateY(40%);
  }
}
@media screen and (min-width: 93.75rem) {
  .footer_fixed_wrapper {
    padding-bottom: 1.875rem;
    padding-top: 1.875rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .footer_fixed_wrapper .links_overview {
    margin: 0;
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 20rem) {
  .footer_fixed_wrapper {
    padding-bottom: calc(1.875rem + 1.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 93.75rem) {
  .footer_fixed_wrapper {
    padding-bottom: 3.125rem;
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 20rem) {
  .footer_fixed_wrapper {
    padding-top: calc(1.875rem + 1.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 93.75rem) {
  .footer_fixed_wrapper {
    padding-top: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 2.75rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 75rem) {
  .page_wrapper .main_wrapper {
    width: 65%;
    z-index: 1;
  }
  .page_wrapper .main_wrapper > .container {
    padding: 0 0 0 18%;
  }
  .page_wrapper .side_nav_wrapper {
    -webkit-animation: move_from_right 750ms ease-out;
            animation: move_from_right 750ms ease-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    bottom: 0;
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform: translateX(4em);
            transform: translateX(4em);
    width: 35%;
    z-index: 10;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .header_wrapper .logo_wrapper {
    padding: 1.5em 0;
  }
}
@media screen and (min-width: 75rem) {
  .header_wrapper > .container {
    padding: 0 0 0 18%;
  }
  .header_wrapper .mobile_navigation_wrapper {
    display: none;
  }
  .header_wrapper .logo_wrapper {
    margin: 0;
  }
  .header_wrapper .logo_wrapper .company_logo {
    margin: inherit;
  }
}
@media screen and (min-width: 75rem) {
  .header_content > .container {
    padding: 0 0 0 18%;
  }
}
@media screen and (min-width: 20rem) {
  .slogan_wrapper > p {
    font-size: calc(1rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .slogan_wrapper > p {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .slogan_wrapper > p {
    letter-spacing: calc((1.25rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 80);
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .slogan_wrapper > p {
    font-size: calc(1.25rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .slogan_wrapper > p {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .slogan_wrapper > p {
    font-size: calc(1.375rem + 0.875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .slogan_wrapper > p {
    font-size: 2.25rem;
  }
}
@media screen and (min-width: 75rem) {
  .slogan_wrapper {
    -webkit-animation: slide_in_blurred_left 1.3s cubic-bezier(0.23, 1, 0.32, 1) both;
            animation: slide_in_blurred_left 1.3s cubic-bezier(0.23, 1, 0.32, 1) both;
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
    margin-right: 18%;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 20rem) {
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 35rem) {
  .job_benefit_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    width: 100%;
  }
  .job_benefit_overview .entry .photo {
    display: inline-block;
    -webkit-box-flex: 0;
            flex: 0 0 30%;
    margin-right: 1.5em;
    line-height: 0;
  }
  .job_benefit_overview .entry .title {
    padding-top: 0;
  }
  .job_benefit_overview .entry .content {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  main {
    margin-top: calc(3.75rem + 2.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  main {
    margin-top: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .element_padding {
    margin-bottom: calc(3.75rem + 2.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .element_padding {
    margin-bottom: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .element_padding_top {
    margin-top: calc(3.75rem + 2.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .element_padding_top {
    margin-top: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .element_padding_half {
    margin-bottom: calc(1.875rem + 1.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .element_padding_half {
    margin-bottom: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .element_padding_top_half {
    margin-top: calc(1.875rem + 1.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .element_padding_top_half {
    margin-top: 3.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 35rem) {
  .toggle_offcanvas_menu label .text {
    display: block;
  }
}
@media screen and (max-width: 74.9375rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(0.9375rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((0.9375rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 80);
    font-size: 0.9375rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(0.9375rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .onlinetools_popup_overview .overview_title {
    margin-top: calc(1.875rem + 1.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .onlinetools_popup_overview .overview_title {
    margin-top: 3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .onlinetools_popup_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .onlinetools_popup_overview .touch_link .icon_wrapper {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 0.75em;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 6.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 28.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button {
    width: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button {
    width: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .icon {
    height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .icon {
    height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  input[type="search"] {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  input[type="search"] {
    height: 2.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_search_box {
    height: 4.375rem;
    margin-right: 1.5em;
    width: 4.375rem;
  }
  .toggle_search_box:last-child {
    margin: 0;
  }
  .toggle_search_box > span {
    height: 3.875rem;
    width: 3.875rem;
  }
  .toggle_search_box .icon {
    height: 1.875rem;
    width: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .search_box form[role="search"] button {
    width: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .search_box form[role="search"] button {
    width: 2.75rem;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .overview_title {
    margin-top: calc(1.875rem + 1.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .overview_title {
    margin-top: 3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_preview .entry .image {
    margin-right: 1.5em;
    -webkit-box-flex: 0;
            flex: 0 1 20%;
    min-width: 12.5rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .entry {
    display: block;
  }
  .steuernews_preview .entry .image {
    display: block;
    -webkit-box-flex: 1;
            flex: auto;
    margin-right: 0;
  }
}
@media screen and (min-width: 35rem) {
  .news_image {
    display: inline-block;
    width: 15.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .news_image {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues a {
    line-height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues a {
    line-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_current_issue {
    margin-bottom: calc(0.625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_current_issue {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_current_issue ul {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues_select {
    margin-bottom: calc(0.625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues_select {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview_popup .popup_info .close {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview_popup .popup_info .close {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview_popup .popup_info .close {
    width: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview_popup .popup_info .close {
    width: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview_popup .popup_info .info {
    width: 17.5rem;
  }
}
@media screen and (min-width: 20rem) and (min-width: 20rem) {
  .team_overview_popup .popup_info .info {
    width: calc(17.5rem + 25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 20rem) and (min-width: 93.75rem) {
  .team_overview_popup .popup_info .info {
    width: 42.5rem;
  }
}
@media screen and (min-width: 35rem) {
  .teaser_overview .teaser.touch_link {
    display: -webkit-box;
    display: flex;
  }
  .teaser_overview .teaser.touch_link .icon_wrapper {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 0.75em;
  }
}
@media screen and (min-width: 20rem) {
  .content_bg_image_container .teaser_overview .teaser .button > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_bg_image_container .teaser_overview .teaser .button > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .content_bg_image_container .teaser_overview .teaser .button:active > span,
  .content_bg_image_container .teaser_overview .teaser .button.active > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_bg_image_container .teaser_overview .teaser .button:active > span,
  .content_bg_image_container .teaser_overview .teaser .button.active > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .content_bg_image_container .teaser_overview .teaser .teaser_content {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .content_bg_image_container .teaser_overview .teaser .teaser_content .content {
    -webkit-box-flex: 0;
            flex: 0 1 auto;
    padding-right: 4.5em;
  }
  .content_bg_image_container .teaser_overview .teaser .teaser_content .button_wrapper {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
  }
}
@media screen and (min-width: 20rem) {
  .content_bg_image_container .teaser_overview .teaser .title {
    font-size: calc(1rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_bg_image_container .teaser_overview .teaser .title {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .content_bg_image_container .teaser_overview .teaser .title {
    letter-spacing: calc((1.25rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 80);
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .content_bg_image_container .teaser_overview .teaser .title {
    font-size: calc(1.25rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .content_bg_image_container .teaser_overview .teaser .title {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .keyword_register a {
    line-height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .keyword_register a {
    line-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .keyword_register a {
    width: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .keyword_register a {
    width: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_keywords {
    margin-bottom: calc(0.625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_keywords {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_keywords a {
    line-height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_keywords a {
    line-height: 2.75rem;
  }
}
@media screen and (min-width: 30rem) {
  .youtube_wrapper .overlay {
    padding: 0.75em;
  }
}
@media screen and screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .youtube_wrapper .overlay {
    height: 100%;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 21.875rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  .open_street_map button {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    display: none;
  }
  .open_street_map .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    list-style: none !important;
    margin: 0.75em 0;
    padding: 0;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_entity .hide_image_author {
    display: none !important;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
